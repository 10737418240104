<template>
  <!-- 左侧菜单栏 -->
  <div class="menu-aside">
    <el-menu class="el-menu-vertical-demo" @select="handleSelect" :default-active="activeIndex">
      <el-menu-item v-for="item in menuList" :key="item.id" :index="item.id" :title="item.title">
        <el-icon>
          <!-- 动态渲染icon组件 -->
          <component :is="item.icon" :key="item.icon" />
          <!-- <IconMenu /> -->
        </el-icon>
        <span>{{ item.title }}</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script setup>
import { useRouter, useRoute } from 'vue-router'
import { Menu as IconMenu, Avatar, Tickets } from '@element-plus/icons-vue'
import { computed, onMounted, ref, shallowRef } from 'vue'

// 路由器实例，相当于$router
const router = useRouter()

// 当前的路由地址，相当于$route
const route = useRoute()

// 定义左侧菜单栏
let menuList = ref([])

// 默认激活的菜单下标
const activeIndex = computed(() => {
  const menuObj = menuList.value.find((ele) => ele.path === route.path)
  if (menuList.value.length === 0 || !menuObj) return '-1'
  return menuObj?.id
})

// 菜单点击时触发
function handleSelect (val) {
  const objRouteList = menuList.value.map(({ id, path }) => {
    return {
      id,
      path
    }
  })
  const curMenu = objRouteList.find(ele => ele.id === val)
  router.push(curMenu.path)
}

// 初始化
function init () {
  menuList.value = [
    {
      id: '1',
      name: 'user',
      path: '/manage/user',
      title: '用户管理',
      icon: shallowRef(Avatar)
    },
    {
      id: '2',
      name: 'classification',
      path: '/manage/classification',
      title: '分类管理',
      icon: shallowRef(IconMenu)
    },
    {
      id: '3',
      name: 'blog',
      path: '/manage/blog',
      title: '博客管理',
      icon: shallowRef(Tickets)
    },
    {
      id: '4',
      name: 'picture',
      path: '/manage/picture',
      title: '图库管理',
      icon: shallowRef(Tickets)
    }
  ]
}

onMounted(() => {
  init()
})
</script>
<style lang="scss" scoped>
.el-menu {
  border: none;

  .is-active {
    background-color: #ecf5ff !important;
  }
}
</style>