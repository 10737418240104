<template>
  <div class="page-header-content">
    <!-- 顶部面包屑 -->
    <span>{{ title }}</span>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()

const title = computed(() => {
  return route.meta.title
})
</script>
<style lang="scss" scoped>
.page-header-content {
  padding: 10px 20px;
  border-bottom: 1px solid #eeeeee;
  background-color: #fff;
  color: #498dff;
}
</style>