import layoutAside from '@/components/layout/layout'

// 在主框架之内显示
const frameIn = [
  {
    path: '/',
    redirect: { name: 'index' },
    component: layoutAside,
    children: [
      {
        path: '/manage/index',
        name: 'index',
        meta: {
          title: '首页',
          keepAlive: true
        },
        component: () => import(/* webpackChunkName: "index" */ '@/pages/manage/index/index.vue')
      },
      {
        path: '/manage/user',
        name: 'user',
        meta: {
          title: '用户管理',
          keepAlive: true
        },
        component: () => import(/* webpackChunkName: "user" */ '@/pages/manage/user/index.vue')
      },
      {
        path: '/manage/userEdit/:type/:id?',
        name: 'userEdit',
        meta: {
          title: '用户管理编辑页',
          keepAlive: true
        },
        component: () => import(/* webpackChunkName: "user" */ '@/pages/manage/user/edit.vue')
      },
      {
        path: '/manage/classification',
        name: 'classification',
        meta: {
          title: '分类管理',
          keepAlive: true
        },
        component: () => import(/* webpackChunkName: "classification" */ '@/pages/manage/classification/index.vue')
      },
      {
        path: '/manage/blog',
        name: 'blog',
        meta: {
          title: '博客管理',
          keepAlive: true
        },
        component: () => import(/* webpackChunkName: "blog" */ '@/pages/manage/blog/index.vue')
      },
      {
        path: '/manage/blogInfo/:type/:id?',
        name: 'blogInfo',
        meta: {
          title: '博客管理编辑页',
          keepAlive: true
        },
        component: () => import(/* webpackChunkName: "blog" */ '@/pages/manage/blog/info.vue')
      },
      {
        path: '/manage/picture',
        name: 'picture',
        meta: {
          title: '图库管理页',
          keepAlive: true
        },
        component: () => import(/* webpackChunkName: "picture" */ '@/pages/manage/picture/index.vue')
      }
    ]
  }
]

const routes = [
  {
    path: '/login',
    name: 'login',
    title: '登录',
    component: () => import(/* webpackChunkName: "login" */ '@/pages/login/index.vue')
  }
]

export default [
  ...frameIn,
  ...routes
]