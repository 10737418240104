import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import util from '@/libs/util'
import routes from './routes'


const router = createRouter({
  routes,
  // 使用history路由模式，打包部署到云服务器上刷新页面后会报404
  // history: createWebHistory()
  // 改为hash模式的路由
  history: createWebHashHistory()
})

const whiteList = ['/login']

router.beforeEach((to, from, next) => {
  if (whiteList.indexOf(to.path) === -1) {
    // 非白名单页面，需要先登录
    const isLoginFlag = util.cookies.get('isLoginFlag')
    if (isLoginFlag) {
      next()
    } else {
      next('/login')
    }
  } else {
    next()
  }
})

export default router