<template>
  <!-- 整体页面布局 -->
  <div class="page-content">
    <div class="top-box">
      <span class="desc">nodejs+mysql实现的后台管理系统</span>
      <div class="user-top">
        <span class="user-name">您好，{{ username }}</span>
        <span class="logout-btn" @click="logout">退出登录</span>
      </div>
    </div>
    <div class="main-content">
      <div class="left-box">
        <!-- 菜单区域 -->
        <AsideMenu />
      </div>
      <div class="right-box">
        <!-- 主体区域 -->
        <PageHeader />
        <!-- <keep-alive :include="isKeepAlive"> -->
        <router-view :key="routeKey" />
        <!-- </keep-alive> -->
      </div>
    </div>
    <!-- 底部说明 -->
    <div class="bottom-content">
      <a class="filing-a" href="https://beian.miit.gov.cn/" target="_blank">{{RECORD_NUMBER}}</a> 
      <span class="split-line"> | </span>
      <span class="version-box">版本号version: V1.0.0</span>  
    </div>
  </div>
</template>

<script setup>
// eslint-disable-next-line
import AsideMenu from '@/components/layout/asideMenu.vue'
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import PageHeader from '@/components/layout/pageHeader.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import useStore from '@/store/index'
import * as types from '@/store/mutationTypes'
import util from '@/libs/util'
import { RECORD_NUMBER }  from '@/libs/constant'

const route = useRoute()
const router = useRouter()
const UserInfoStore = useStore().userInfoStore

console.log('当前route对象：', route)

const routeKey = computed(() => {
  return route.fullPath
})

const isKeepAlive = computed(() => {
  return route.meta && route.meta.keepAlive
})

// 当前登录的用户用户名
const username = computed(() => {
  return UserInfoStore.getUserInfo.username
})

// 退出登录
function logout() {
  ElMessageBox.confirm('确认退出当前账号吗?', '提示', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(() => {
      // 调用退出登录的接口
      router.push('/login')
      // 清空缓存的信息
      util.cookies.remove('token')
      util.cookies.remove('isLoginFlag')
      UserInfoStore[types.REMOVE_USER_INFO]()
    })
    .catch(() => {
      ElMessage({
        type: 'info',
        message: '已取消操作'
      })
    })
}
</script>
<style lang="scss" scoped>
.page-content {
  background-color: #f6f7fb;
  .top-box {
    margin: 0;
    height: 40px;
    line-height: 40px;
    background-color: #fff;
    border-bottom: 1px solid #eeeeee;
    padding: 0 20px;
    display: flex;
    .desc {
      color: #8a8b9b;
    }
    .user-top {
      flex: 2;
      text-align: right;
      .user-name {
        color: #8a8b9b;
        font-size: 14px;
        margin-right: 10px;
        font-weight: 400;
      }
      .logout-btn {
        padding: 0 20px;
        cursor: pointer;
        font-size: 15px;
        color: #498dff;
      }
    }
  }
  .main-content {
    display: flex;
    height: calc(100vh - 140px);
    .left-box {
      width: 200px;
      height: 100%;
      margin-right: 20px;
      background-color: #fff;
    }
    .right-box {
      width: 100%;
      border-radius: 8px;
      overflow-y: scroll;
    }
  }
  .bottom-content {
    height: 60px;
    line-height: 60px;
    margin-top: 20px;
    background-color: #fff;
    // position: absolute;
    // bottom: 0;
    // left: 0;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    font-size: 14px;
    .split-line {
      color: #ccc;
    }
    .filing-a {
      color: #1890ff;
      text-decoration: none;
    }
  }
}
</style>