<template>
  <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component" />
    </keep-alive>
  </router-view>
</template>

<script>
export default {
  name: 'App',
  components: {},
  mounted() {
    // 解决浏览器窗口尺寸改变时，有el-table组件的页面报错
    const debounce = (fn, delay) => {
      let timer = null
      return function () {
        let context = this
        let args = arguments
        clearTimeout(timer)
        timer = setTimeout(function () {
          fn.apply(context, args)
        }, delay)
      }
    }

    const _ResizeObserver = window.ResizeObserver
    window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
      constructor(callback) {
        callback = debounce(callback, 16)
        super(callback)
      }
    }
  }
}
</script>

<style lang="scss">
body {
  margin: 0;
}
</style>
