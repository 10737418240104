import { defineStore } from 'pinia'
import * as types from '../mutationTypes'


// 你可以对 `defineStore()` 的返回值进行任意命名，但最好使用 store 的名字，同时以 `use` 开头且以 `Store` 结尾。
// 第一个参数是你的应用中 Store 的唯一 ID。
const useUserInfoStore = defineStore('userInfoStore', {
  persist: true,
  state: () => {
    return {
      userInfo: { username: '' }
    }
  },
  getters: {
    getUserInfo: state => state.userInfo
  },
  actions: {
    [types.SET_USER_INFO] (data) {
      this.userInfo = data
    },
    [types.REMOVE_USER_INFO] () {
      this.userInfo = {
        username: ''
      }
    }
  }
})
export default useUserInfoStore