import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
// 引入公共样式
import '@/assets/style/page-table.scss'
// 自定义代码高亮时的样式
import '@/assets/style/myMavonEditor.css'

// import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import 'mavon-editor/dist/css/index.css'  // 引入样式文件
import mavonEditor from 'mavon-editor'  // 引入markdown组件

const app = createApp(App)
const pinia = createPinia()
// 进行pinia持久化
pinia.use(piniaPluginPersistedstate)

app.use(mavonEditor)

app.use(router)
app.use(ElementPlus, { zIndex: 3000 })
app.use(pinia)
// 全局注册elementUI的图标，改为按需引入
// for (const key in ElementPlusIconsVue) {
//   if (Reflect.has(ElementPlusIconsVue, key)) {
//     // 避免与保留关键字冲突
//     if (key === 'Menu') {
//       app.component('IconMenu', ElementPlusIconsVue[key])
//     } else {
//       app.component(key, ElementPlusIconsVue[key])
//     }
//   }
// }

app.mount('#app')
